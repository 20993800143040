@import '../imports.scss';

.SmallCard {
  width: 100%;
  border-radius: 4px;
  box-shadow: 0 0 16px 0 rgba(89, 168, 214, 0.25);
  margin: 12px 0;
  overflow: hidden;
  height: 60px;
  max-width: unset;

  .card-info {
    min-width: 0;
    flex: 1 1 auto;
    padding: 10px;
  }

  .card-picture {
    width: 65px;
    min-width: 65px;
  }

  .card-title {
    min-width: 0;
    color: $charcoal-grey;
    font-size: 16px;
    margin-bottom: 8px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .card-subtitle {
    color: $ocean-blue;
    font-size: 12px;
  }

  .card-actions {
    flex: 0 0 auto;
    padding: 10px;
  }
}