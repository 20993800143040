@import "../imports.scss";
.Login {
  background: $ocean-blue;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 100vh;

  .content {
    flex: 0 1 350px;
    margin: 32px;
    color: white;
    text-align: center;
  }

  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 24px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .Button {
    margin-top: 40px;
    padding-left: 24px;
    padding-right: 24px;
    box-shadow: 0 0 16px 0 darken(rgba(86, 124, 147, 0.5), 25%);
  }
}