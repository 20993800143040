.SafetyField {
  .safety-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .Button {
      font-size: 16px;
      min-height: unset;
      padding: 5px 10px;
    }

    .safety-actions {
      display: flex;
      flex-direction: row;
      margin-left: 16px;
      .Button {
        margin: 3px;
      }
    }
  }
}