table.DataTable {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0px;

  th,
  td {
    padding: 0.3rem;
    text-align: left;
    border: 1px solid gray;
  }
}
