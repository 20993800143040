@import './zeplin.scss';
@import '../node_modules/@upgrowth/react-fulcrum/lib/defaults.scss';
@import '../node_modules/include-media/dist/_include-media.scss';

$breakpoints: (
  phone: 597px,
  tablet: 768px,
  desktop: 1024px
);

$primary: $ocean-blue;
$secondary: $soft-green;
$text: $charcoal-grey;
$neutral: $duck-egg-blue;
$error: $coral;
$error-dark: #f73a36;

$font-family: 'Quicksand', Helvetica, Arial;
$font-weight-normal: 400;
$font-weight: $font-weight-normal;

$image-background: linear-gradient(to right top, $primary);
$message-column-width: 576px;

@mixin stacked-card {
  //color: $charcoal-grey !important;
  font-size: 16px;
  text-decoration: none;
  padding: 12px 12px 16px 12px;
  border-radius: 4px;
  margin: 0 16px;
  max-width: unset;
  box-shadow: 0 0 16px 0 rgba(89, 168, 214, 0.25);

  &.variant-light {
    color: $text;
  }
  &:first-child {
    margin-top: 16px;
  }

  &:last-child {
    margin-bottom: 16px;
  }

  .ProfilePreview:last-child {
    margin-bottom: 0;
  }
}

.NavMenu {
  flex-direction: inherit;
}

.stacked-card {
  @include stacked-card;
}
