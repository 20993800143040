@import '../imports.scss';

.MainNav {
  width: 56px;
  flex-direction: column;
  align-items: center;
  padding: 16px 0;

  .NavMenu {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .monash-logo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0 !important;

    width: 33px;
    height: 43px;
    min-height: 43px;

    img {
      max-width: 100%;
      max-height: 100%;
    }

    > .NavItem {
      padding: 0;
      width: 100%;
    }
  }

  .Avatar {
    width: 40px;
    height: 40px;
    min-width: 40px;
    max-width: 40px;
    margin: 0;
  }

  .avatar-link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 8px;
  }

  .NavItem {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 6px;

    img {
      opacity: 0.5;
    }
    justify-content: center;

    &.active {
      background-color: transparent;

      div.nav-label {
        color: rgba(255, 255, 255, 1);
        font-weight: normal;
      }
      img {
        opacity: 1;
      }
    }

    div.nav-label {
      margin-top: 4px;
      font-size: 10px;
      color: rgba(255, 255, 255, 0.5);
    }
  }
  .sections {
    margin: 40px 0;
    > * {
      margin: 10px 0;
    }
  }

  @include media('≤phone') {
    position: fixed;
    bottom: 0;
    flex-direction: row;
    padding: 0px 12px;
    width: 100%;

    .nav-label {
      display: none;
    }

    .monash-logo {
      height: 32px;
    }

    .NavMenu {
      justify-content: space-between;
      flex: 1 0 100%;
    }
    .sections {
      margin: 0;
      display: flex;
      //width: 200px;
      flex-direction: row;
      > * {
        margin: 0 10px;
      }
    }
  }
}
