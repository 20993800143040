@import "../imports.scss";
.FriendsScreen {

  background-color: rgba(0, 108, 171, 0.85);

  .content {
    margin: 24px;

    div {
      margin-top: 24px;
      padding-bottom: 0px;
    }
  }

  .Badge {
    float: right;
    margin: 0;
  }

  .LinkButton {
    text-decoration: none;
    color: rgba(222, 242, 255, 0.8);
  }

  .friend-card-link {
    text-decoration: none;
  }
  .friend-card {
    @include stacked-card;
    .ProfilePreview {
      margin-bottom: 0;
    }
  }
}