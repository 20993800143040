@import "../imports.scss";

.Screen {
  display: flex;
  flex-direction: column;
  //max-height: 100vh;
  //height: 100vh;
  width: 100%;
  max-width: 100%;
  //position: fixed;
  position: relative;
  z-index: 0;
  flex: 1 1 0;

  .screen-header, .screen-footer {
    flex: 0 0 auto;
    z-index: 1;
  }



  .screen-content {
    flex: 1 1;
    flex-basis: 0;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 0;
    min-height: 0;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;

  }

  .screen-footer {
    //margin-bottom: 44px;
  }

  @include media(">phone") {
    height: 100vh;
  }
}

.Select.is-open { position: relative; z-index: 1000; }