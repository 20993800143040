@import '../imports.scss';

@mixin modbutton($color, $negColor) {
  background-color: transparent;
  color: white;
  border: 1px solid $color;
  &:hover, &.active {
    background-color: $color;
    color: $negColor;
  }

  &.active:hover {
    background-color: transparent;
    color: white;
  }
}

.ModerationTools {
  margin-left: 0;
  margin-right: 0;
  position: relative;

  .open-tools {
    background-color: $cornflower-blue;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s;
    padding-right: 64px;
  }

  &.open {
    .open-tools {
      max-height: 100px;
      margin-bottom: 12px;
    }
  }

  .tools {
    padding: 12px 8px;
    overflow-x: scroll;
    white-space: nowrap;

    &:last-child {
      margin-right: 0;
    }
  }

  .Button {
    margin-right: 16px;
  }

  .ban {
    @include modbutton($coral, white);
  }
  .mute {
    @include modbutton(#ffdd89, $cornflower-blue);
  }
  .promote {
    @include modbutton(#99f9c2, $cornflower-blue);
  }

  .toggle {
    position: absolute;
    top: 8px;
    right: 35px;
    height: 40px;
    width: 40px;
    cursor: pointer;
    text-align: right;
  }

  @include media("≤phone") {
    .toggle {
      right: 23px;

    }

    .tools {
      white-space: nowrap;
      overflow-x: auto;
      padding-right: 50px;
    }
  }
}