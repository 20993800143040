@import '../imports.scss';

// Same as modbutton
@mixin actionbutton($color, $negColor) {
  background-color: transparent;
  color: inherit;
  border: 1px solid currentColor;
  margin: 1em 0.1em 0 0.2em;

  font-size: 12px !important;
  min-height: unset !important;
  padding: 5px 15px !important;

  &:focus {
    background-color: inherit
  }

  &:hover, &.active {
    background-color: $color;
    color: $negColor;
  }

  &.active:hover {
    background-color: transparent;
    color: inherit;
  }
}

.Message {
  @include stacked-card;

  max-width: $message-column-width;
  margin: 12px auto;
  padding: 0;

  overflow: hidden;
  padding-bottom: 24px;
  margin-bottom: 16px;

  &.showingHeader > *:first-child {
    padding-top: 24px;
  }

  h5 {
    font-size: 16px;
    color: $ocean-blue;
    margin-bottom: 0;
  }

  > * {
    margin-left: 24px;
    margin-right: 24px;
  }

  .message-details {
    margin-top: 12px;
    font-size: 12px;
    margin-bottom: 12px;
  }

  .reply {
    @include actionbutton($ocean-blue, white)
  }

  .like {
    @include actionbutton($ocean-blue, white)
  }

  &.simple {
    margin: 0;
    border-radius: 0;
  }

  .message-body {
    white-space: pre-line;
    font-size: 20px;
    word-wrap: break-word;
  }

  .message-footer {
    margin-top: .5em;
  }

  .attached-image {
    max-width: 100%;
    margin-top: 24px;
  }

  &.me {
    background-color: $cornflower-blue;
    color: white;

    &:not(.showingHeader) {
      margin-bottom: -4px;
      padding-top: 12px;
      .message-body {
        padding-bottom: 4px;
        word-wrap: break-word;
      }
    }

    .mentions > h5 {
      color: white;
    }
  }

  &.best-post {
    background-color: $soft-green;
    color: white;

    .mentions > h5 {
      color: white;
    }
  }

  @include media("≤tablet") {
    margin: 12px 16px;

    padding-bottom: 12px;

    &.me {
      padding-top: 0;
    }
    > *:first-child {
      margin-top: 12px;
    }

    > * {
      margin-left: 12px;
      margin-right: 12px;
    }
  }

  &.muted {
    display: none;
  }

  &:last-child {
    margin-bottom: 0;
  }
}