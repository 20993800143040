@import '../imports.scss';

$card-size: 268px;

.MagazineCard {
  $size: $card-size;
  width: $size;
  object-fit: contain;
  border-radius: 4px;
  background-color: $white;
  box-shadow: 0 0 16px 0 rgba(89, 168, 214, 0.25);
  overflow: hidden;
  position: relative;

  a {
    text-decoration: inherit;
  }

  .magazine-card-text {
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1 auto;

    .magazine-card-title {
      font-size: 20px;
      color: $charcoal-grey;
    }
    .magazine-card-data {
      color: $ocean-blue;
      margin-top: 8px;
    }
  }

  .magazine-card-picture {
    background: $image-background;
    background-size: cover;

    height: 268px;
    .magazine-card-picture-image {
      background-size: cover;
      width: 100%;
      height: 100%;
    }
  }

  .magazine-badge {
    position: absolute;
    top: 16px;
    right: 16px;
    .unread-badge {
      background-color: $coral;
      &:empty {
        display: none;
      }
      font-size: 20px;
    }


    &:empty {
      display: none;
    }
  }

}

@include media("≤phone") {
  .GroupsMagazine {
    .Magazine > .desktop-view {
      align-items: stretch;
      padding: 12px;
    }
    .MagazineCard {
      margin: 6px 0;
      max-width: 100%;
      flex-direction: row;
      flex-grow: 1;

      .magazine-card-picture {
        min-width: 60px;
        max-width: 60px;
        height: auto;
      }

      .magazine-card-title {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        flex-basis: 100%;
        min-width: 0;
        font-size: 16px;
      }

      .magazine-card-data {
        font-size: 12px;
      }

      .magazine-card-text {
        overflow: hidden;
        padding: 10px;
      }

    }
  }
}
