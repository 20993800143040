@import '../../imports.scss';

.ProcessQueueModal {
  &.main {
    height: 100%;

    .no-file {
      color: lightslategray;
    }

    .Files {
      margin-top: 24px;
      border-top: solid 1px rgba(0, 0, 0, 0.1);

      .File {
        padding: 18px 6px;
        border-bottom: solid 1px rgba(0, 0, 0, 0.1);

        .heading {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .title {
          font-size: larger;
          color: $ocean-blue;
        }

        .subtitle {
          margin-top: 6px;
          margin-bottom: 12px;
          color: lightslategrey;
        }

        p {
          margin-top: 6px;
          margin-bottom: 6px;
        }

        .status {
          &.inProgress {
            color: orange;
          }
          &.processed {
            color: green;
          }
          &.failed {
            color: red;
          }
        }

        .error {
          color: red;
        }

        .buttons {
          margin-top: 12px;
          display: flex;
          // justify-content: space-between;

          span {
            color: $ocean-blue;
            cursor: pointer;
            margin-right: 24px;
            text-decoration: none;

            &:hover {
              color: $cornflower-blue;
            }
          }
        }
      }
    }
  }

  &.info {
    width: 65%;

    ul.list {
      max-height: 70vh;
      overflow-y: scroll;

      li {
        margin-bottom: 12px;
      }
    }

    p {
      margin: 0;
      padding-bottom: 6px;

      &.code,
      &.subtitle {
        color: slategray;
      }
    }
  }
}
