@import "../imports.scss";

.AddFriend {

  .screen-content {
    padding: 24px;
    max-width: 600px;
  }

  .Form {

    .FormRow {
      margin: 12px 0;
    }
    .email-line {
      position: relative;
      display: flex;
      flex-direction: row;

      .Input {
        flex: 1 1 0;
        font-size: 20px;
        padding: 18px 118px 15px 18px;
        text-align: right;
      }
      .suffix {
        position: absolute;
        right: 8px;
        align-self: center;
        font-size: 10px;
        font-weight: 400;
      }
    }
    .Button {
      margin: 0;
    }
  }

}