@import '../imports.scss';

.Spread {
  @include media(">tablet") {
    padding: 36px;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    align-items: center;

    .spread-content {
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;
      align-items: center;
    }

    .spread-screens {
      flex: 1 1 auto;
      display: flex;
      flex-direction: row;

      > * {
        max-width: 384px;
        flex: 1 1 384px;
        min-width: 30%;
        margin-right: 28px;
        &:last-child {
          margin-right: 0;
        }
      }

      h2, .Tabs, .nextBtn {
        display: none;
      }
    }

    .spread-header {
      position: relative;
      width: 100%;
      height: 150px;
      > * {
        position: absolute;
        width: 100%;
        height: 100%;
      }
    }

    .spread-footer {
      // hack to align "confirm details" button with .spread-content
      width: 796px;
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;
      align-items: flex-end;
    }
  }

  @include media("≤tablet") {
    .spread-header, .spread-footer {
      display: none;
    }

    .ActiveWrapper:not(.active) { display: none }
  }
}