@import "../imports.scss";


.user-card {
  @include stacked-card;
  padding: 0;

  .ProfilePreview {
    padding: 12px;
    margin: 0;

  }
}
