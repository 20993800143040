@import '../imports.scss';

.SectionNav {
  flex-direction: row !important;
  align-items: center;
  padding: 0 8px;

  .NavMenu {
    justify-content: space-between;
    align-items: center;
    flex: 1 0 auto;
    max-width: 100%;
  }

  h2 {
    flex: 1 1 auto;
    font-size: 16px;
    color: $ocean-blue;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .context-placeholder {
    width: 40px;
    height: 100%;
  }

  .Button {
    margin: 0;
  }
}