@import "../imports.scss";

.GroupProfile {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-blend-mode: multiply;
  padding: 48px 24px;
  color: white;
  background-size: cover;
  background-position: center;

  .group-name-text {
    font-size: 16px;
    font-weight: normal;
  }

  .member-count {
    font-size: 12px;
    margin-top: 5px;
  }

  .group-description {
    margin-top: 15px;
  }

  .profile-label {
    font-size: 16px;
    display: block;
  }

  .profile-data {
    width: 100%;
    max-width: $message-column-width;
    margin: 0 auto;
    min-height: 220px;
  }

  @include media("≤phone") {
    .ProfilePicture {
      width: 100%;
    }

    .profile-data {
      padding: 0;
    }
  }

  .group-description .text {
    margin-top: 10px;
    white-space: pre-wrap;
    font-size: 20px;
    a {
      color: inherit;
    }
  }

  .actions {
    .Button {
      color: white;
      background: transparent;
      border-color: white;
      margin: 23px 12px 0 0;

      &:hover {
        background: rgba(255,255,255,0.4);
      }
      &:focus {
        background: rgba(255,255,255,0.3);
      }
    }
  }
}