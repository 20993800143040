@import '../imports.scss';

.Tabs {
  margin: 24px;
}

.OfficialPost {
  min-height: 320px;
  background-size: cover;
  padding: 24px;
  color: white;
  flex: 0 0 auto;
  overflow: hidden;

  .logo {
    margin: 0 12px 0 0;
    height: 40px;
  }

  .Column {
    justify-content: space-around;
  }

  p.main-title {
    font-size: 40px;
    font-weight: 300;

    @include media("<=phone") {
      font-size: 24px;
    }
  }

  .comments-count {
    color: white;
    text-decoration: none;
    text-align: right;
    font-size: 16px;
    font-weight: 500;
  }

  .Linkify {
    word-break: break-word;

    a {
      color: $cornflower-blue;
    }
  }
}