@import '../imports.scss';
.fr-box {
  width: 100%;
}

.Chatbox {
  $font-size: 16px;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  align-items: flex-end;
  padding: 8px 0;
  max-width: $message-column-width;
  margin: 0 auto;
  position: relative;
  box-shadow: none !important;

  .attachments {
    left: 8px;
  }

  &.withAttachments .add-attachment-button {
    background-color: $cornflower-blue;
    color: white;
  }

  .image-attachment-reminder {
    background-color: $cornflower-blue;
    padding: 5px 15px;
    font-size: 12px;
    border-radius: 15px;
    border: 1px solid $ocean-blue;
    color: white;
    opacity: 0;
    position: absolute;
    top: -23px;
    cursor: pointer;

    &.visible {
      opacity: 1;
    }
  }

  .chat-field {
    flex: 1;
    border: 1px solid $ocean-blue;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    align-items: stretch;
    width: 100%;
  }

  textarea {
    flex: 1;
    resize: none;
    font-size: $font-size !important;
    padding: 10px 16px;
    outline: none;
    border: none;

    &:focus {
      outline: none;
    }
  }

  &.banned {
    background-color: $coral;
    color: white;
    font-weight: bold;
    text-align: center;
    display: block;
    height: 56px;
    font-size: 20px;
    line-height: 35px;
  }

  @include media('≤tablet') {
    padding: 8px 16px 60px;
  }
}
