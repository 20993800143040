@import '../imports.scss';

.GroupPreview {
  .card-picture {
    width: 65px;
    min-width: 65px;
    background: $image-background;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .join-group-button {
    font-size: 24px;
    color: $cornflower-blue;
    > * {
      margin-top: -5px;
    }
  }

  .joined-indicator {
    $size: 40px;
    width: $size;
    height: $size;
    overflow: hidden;
    border-radius: 100%;
    min-height: $size;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    background-color: $secondary;
    color: white;
    font-size: 24px;
    &.joining {
      animation: spin 1s linear;
      animation-iteration-count: infinite;
      color: $secondary;
      background-color: transparent;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}