@import '../imports.scss';

.Onboarding {

  height: 100%;
  //
  > .screen-content {
    padding: 12px;
  }

  .Input {
    background-color: $very-light-blue;
  }

  
  .nextBtn {
    display: flex;
    flex-direction: row-reverse;
  }

  .Picture {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;

    .Form {
      display: flex;
      flex-direction: column;
      flex: 1 0 auto;

    }
  }

  .Checkbox {
    margin-left: 0px;
    padding-right: 0px;

    > input {
      flex: 0 0 auto;
    }
  }

  .Label {
    color: $ocean-blue;
    margin-left: 0;
  }

  h2 {
    color: $soft-green;
    font-size: 32px;
    font-weight: 500;
  }

  h3 {
    font-size: 16px;
  }

  h4 {
    margin: 12px 0;
  }
  h5 {
    margin: 12px 0 24px 0;
    color: $charcoal-grey;
    font-size: 20px;
    font-weight: 500;

    &.help-text {
      color: $ocean-blue;
    }
  }

  p {
    color: $charcoal-grey;
  }

  .leaflet-container {
    width: 100%;
    height: 320px;
  }

  .Button {
    white-space: nowrap;
  }

  .Magazine {
    display: flex;
    flex-direction: row;
    justify-content: center;
    .desktop-view {
      display: block;
      margin: 0 auto;
    }
  }

  .Screen {
    display: block;
    height: auto;
  }

  @include media(">tablet") {
    .GroupSpread {
      //padding-top: 66px;
      .GroupsMagazine .screen-content {
        overflow: visible;
      }
    }
  }
}

.switch-group {
  display: inline-flex;
  flex-direction: row;
  align-items: center;

  .Switch {
    padding-right: 0;
  }

  .yes {
    color: $secondary;
  }

  &.yes .yes, &.no .no {
    font-weight: bold;
  }
}

.UpdatedTermsModal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 400px;

  label span {
    font-size: 16px;
  }
  h3 {
    margin-top: 0;
  }
}