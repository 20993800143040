@import '../imports.scss';

.PromotedPosts {
  .Posts {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin: 0 auto;

    .Post {
      margin: 12px;
      flex: 0 0 auto;
      .container {
        height: 100%;
        flex-direction: column;
        background-color: $dark-sky-blue;
        display: flex;
        justify-content: space-between;
      }
    }
  }

  @include media('<=tablet') {
    padding: 0;
  }
  @include media('<=phone') {
    .Posts {
      flex-direction: column;
      flex-wrap: nowrap;
    }
  }
}
