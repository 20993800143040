@import '../imports.scss';

.Dashboard {

  .section-title {
    color: $ocean-blue;
    margin: 24px 12px 0 12px;
    padding: 0 8px;
  }

  @include media("≤tablet") {
    .section-sidebar {
      display: none;
    }
    .LatestActivity {
      padding: 0;
    }
  }

  @include media(">tablet") {
    .screen-content {
      .LatestActivity {
        display: none;
      }
    }
  }

  .section-sidebar {
    overflow-y: auto;
    background-color: $dark-sky-blue;
    min-width: 370px;
  }

  .LatestActivity {
    margin: 0 auto;

    .Posts {
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }
    .Post {
      margin: 12px;
    }
  }

  .posts {
    margin: 24px auto;
    max-width: 90%;
    justify-content: space-evenly;
  }

  .title {
    font-size: 16px;
    font-weight: normal;
  }

  .subtitle {
    font-size: 12px;
  }
}