@import "../imports.scss";

.Maintenance {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: $ocean-blue;

  .message {
    display: flex;
    flex: 0 0 auto;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 64px 64px;
    max-width: 600px;
    margin: 15px;
    color: white;

    .logo {
      margin-bottom: 32px;
    }

    .text {
      font-size: 20px;
      p {
        text-align:  center;
      }
    }
   
  }
  @include media("<=phone"){
    .message {
      padding: 64px 16px;
    }
  }

  a {
    color: white;
  }
}
