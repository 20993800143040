@import '../imports.scss';

.Conversation {
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;

  //padding: 0 28px; TODO Move this around

  @include media("≤phone") {
    padding: 0;
  }

}

.conversation-header {
  box-shadow: 0 -2px 4px 0 rgba($dark-sky-blue, 0.25) inset;
  //padding-top: 24px;
  //padding-bottom: 32px;
  background-color: rgba($dark-sky-blue, 0.25);

  .conversation-header-contents {
    //max-width: 576px;
    //margin: 0 auto;
  }

  .conversation-label {
    font-size: 20px;
    color: $ocean-blue;
  }

}
