@import '../imports.scss';

.ProfileScreen {
  .actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 48px;
  }

  .Button {
    white-space: nowrap;
  }

  .profile-actions {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    > .Column {
      width: 338px;
    }

    .Checkbox {
      font-size: 16px;
      color: $ocean-blue;
      margin: 12px 0;
    }

    .signout-button {
      margin: 24px auto;
      font-size: 18px;
      border-radius: 24px;
      padding: 10px 36px;
      min-height: unset;
    }
  }

  .retry-button {
    margin-left: 0;
    margin-bottom: 32px;
  }
}