@import '../../imports.scss';

.PreferredNameModal {
  max-width: 640px;

  p {
    margin-top: 0;
    font-size: larger;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    gap: 8px;

    button.Button {
      margin: 0;
    }
  }
}
