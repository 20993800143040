@import '../../imports.scss';

.CSVUploadModal {
  height: 100%;

  button.link {
    background: none;
    border: none;
    cursor: pointer;
    color: $ocean-blue;
    font-family: $font-family;
    &:hover {
      color: $cornflower-blue;
    }
  }

  .Dropzone {
    .DropzoneBox {
      cursor: pointer;
      border-radius: 0.5rem;
      width: auto;
      min-height: 10rem;
      height: 24vh;
      margin: 2rem 0;
      border: dashed $ocean-blue;
      display: flex;
      align-items: center;
      justify-content: center;

      &.Hover {
        background-color: lighten($duck-egg-blue, 10%);
      }

      &.Loading {
        opacity: 0.5;
      }
    }
  }

  .Upload {
    margin: 0;
    width: 100%;
  }

  .Error {
    color: red;
    margin-bottom: 2rem;
  }
}
