.Mention {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  margin-right: 24px;

  .Avatar {
    margin-left: 0;
  }

  color: inherit !important;
  text-decoration: inherit;
}