@import '../imports.scss';

.Group {
  .Message {
    .ProfilePreview.isAdmin {
      background-color: $ocean-blue;
      color: white;
      margin: 0 !important;
      padding: 12px 24px 12px 24px;
    }
  }
  @include media("<=phone"){
    .Message {
      .ProfilePreview.isAdmin {
        padding: 12px;
      }
    }
  }
}