@import '../imports.scss'; 

$frame-border-style: $duck-egg-blue solid thin;
$frame-size: 28px;

.profile {
  margin: 0 auto;
  width: 100%;
  max-width: 800px - 28px;
  padding: 28px;


  > div {
    margin-bottom: 32px;
  }

  h3 {
    font-size: 20px;
    font-weight: normal;
    color: $ocean-blue;
  }

  .profile-info {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .profile-picture {
      padding-right: 8px;
      margin: 0 auto;
    }

    .profile-details {
      margin: 0 auto;
      padding-left: 8px;
      flex: 1 1 50%;

      .Field {
        margin-bottom: 0;
      }

      .Input {
        padding: 8px;
        background-color: $dark-sky-blue;
        color: $charcoal-grey;
      }

      .help-text {
        font-size: 12px;
      }

      > div {
        padding: 8px;
      }

      .given-name {
        margin-right: 24px;
      }
    }

    .interest-cards {
      min-width: 300px;
      margin: 0 auto;
      overflow: hidden;
    }
  }

  .profile-label {
    display: block;
    font-size: 12px;
    color: $ocean-blue;
    margin-bottom: 8px;
  }

  .text {
    font-size: 20px;
    color: $charcoal-grey;
    padding-top: 5px;
  }

  .profile-bio-textarea{
    white-space: pre-line;
    word-wrap: break-word;
    max-width: -webkit-fill-available;
  }

  .GroupPreview {
    display: inline-flex;
    min-width: 326px;
    margin: 4px auto;
  }

  .profile-location-set {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .name-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .interest-cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .SmallCard {
      max-width: 700px;
    }
  }

  .standing-roles {
    margin-top: 5px;
    display: flex;
    flex-wrap: wrap;
  }

  @include media("≤phone") {
    padding: 12px;
    .GroupPreview {
      display: flex;
      margin: 0;
      min-width: 100%;
    }
    .interest-cards{
      flex-direction: column;
      .Card {
        margin-top: 8px;
      }
    }
  }

}