.Admin {
  .Actions {
    display: flex;
    flex-wrap: wrap;
  }

  .Card {
    justify-content: space-between;

    h3 {
      margin: 0;
    }

    h4 {
      margin: 0;
    }

    pre {
      white-space: normal;
    }

    button {
      width: 100%;
    }
  }
}
