@import '../imports.scss';

.Button {
  $size: 40px;
  flex: 0 0 40px;

  &.shape-round {
    width: $size;
    height: $size;
    overflow: hidden;
    border-radius: 100%;
    min-height: $size;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  &.shape-pill {
    border-radius: 20px;
    font-size: 16px !important;
    padding: 10px 16px !important;
    min-height: unset;
  }

  &.variant-secondary {
    border-radius: 28px;
    font-weight: 400;
  }

  &.outline.variant-light {
    border: 1px solid $primary;
  }
  &.outline.variant-neutral {
    border: 1px solid $neutral;
    background: white;
    &:hover {
      background-color: $neutral;
    }
  }

  &.reject {
    background-color: $error;
    &:focus {
      background-color: $error;
    }
    &:hover {
      background-color: $error-dark;
    }
  }
}
