@import '../imports.scss';

$frame-border-style: $duck-egg-blue solid thin;
$frame-size: 28px;

.EditableProfilePicture {

  .Dropzone {
    width: auto !important;
    height: auto !important;
    border-width: 0px !important;
  }

  .fileSelector {
    text-decoration: underline;
    color: $charcoal-grey;
  }


  .frame-edge {
    width: $frame-size;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.left div {
      border-left: $frame-border-style;
    }

    &.right div {
      border-right: $frame-border-style
    }

    div {
      height: $frame-size;

      &.top {
        border-top: $frame-border-style
      }

      &.bottom {
        border-bottom: $frame-border-style
      }
    }
  }
}

.ProfilePicture {
  height: 180px;
  display: flex;
  justify-content: space-between;

  position: relative;


  .caption {
    position: absolute;
    margin-left: 50%;
    transform: translateX(-50%);
    left: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-items: center;
    align-items: center;
    text-align: center;
  }

  .Avatar.size-xlarge {
    background-color: $very-light-blue;
    color: $charcoal-grey;
    width: 180px;
    height: 180px;
    margin: 0px;

    &:hover {
      transform: none;
      box-shadow: none;
    }

    p {
      text-align: center;

      a {
        color: $charcoal-grey;
      }
    }


    .inputfile {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }

    label {
      display: inline;
      text-decoration: underline;
    }
  }
}