@import '../imports.scss';


.SearchField {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  flex: 0 0 auto;

  .search-icon {
    flex: 0 0 40px;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: $ocean-blue;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
  }

  .Input {
    font-size: 16px;
    border-bottom: 1px solid $ocean-blue;
    background-color: transparent;
    padding: 9px 0;
    border-radius: 0 !important;
    -webkit-appearance: none;
  }

  @include media("≤phone") {
    padding: 12px 0;
  }


}