@import '../imports.scss';

.GroupDetailsEditor {
  .Form {
    margin: 52px 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .Label {
      color: $ocean-blue;
      font-size: 12px;
    }

    .Select-clear {
      display: none;
    }

    .field-heading {
      margin-top: 32px;
    }

    .spread-footer {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      margin-bottom: 24px;

      .Button {
        margin: 4px 0;
        min-width: 140px;
      }

      .error {
        color: $error;
      }
    }

    h5 {
      margin: 0 0 6px 0;
      color: $charcoal-grey;
      font-size: 20px;
      font-weight: 500;
    }

    p {
      margin: 8px 0 16px 0;
    }

    .EditableProfilePicture {
      flex: 1 1 300px;
      margin: 0 9px 24px 9px;
    }

    .text-info {
      flex: 1 1 auto;
      margin: 0 9px;
    }

    .Field {
      margin-bottom: 24px;
      margin-right: 0;
    }

    .Switch.variant-primary .react-toggle-thumb{
      background: grey;
    }

    .Switch.variant-primary .react-toggle--checked .react-toggle-thumb{
      background: $primary;
    }

    .Textarea {
      height: 500px;
    }
    .react-tagsinput {
      padding-left: 18px;
      min-height: 53px;

      &,
      * {
        font: inherit;
      }
      &.react-tagsinput--focused {
        border-color: $primary;
      }

      .react-tagsinput-tag {
        padding: 12px 5px 12px 18px;
        background-color: $neutral;
        border-color: $primary;
        color: $primary;
        position: relative;
        display: inline-flex;
        flex-direction: row;

        .Button {
          flex-basis: 20px;
          margin: 0 0 0 9px;
          font-size: 10px;

          min-width: 20px;
          min-height: 20px;
          max-width: 20px;
          height: 20px;
          width: 20px;
          display: block;
          > * {
            margin-top: -3px;
          }
        }
      }
    }
  }
}
