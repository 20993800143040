@import '../imports.scss';

.eventsListContainer {
  width: 100%;
  height: 100vh;
  overflow: scroll;

  h1 {
    color: #5cb985;
    font-size: 32px;
    padding: 0 40px;
    margin: 40px 0 0;
  }
}

.eventsList {
  list-style: none;
  width: 100%;
  padding: 20px 40px;

  h3 {
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    margin: 0 0 20px;

    &.loading {
      color: #afafaf;
    }
  }

  li {
    background: #fff;
    margin: 0 0 20px;
    box-shadow: 0 0 16px 0 rgba(89, 168, 214, 0.25);
    @include media('>phone') {
      display: flex;
    }

    &.past {
      opacity: 0.6;
    }
  }
}

.eventImg {
  width: 100%;
  height: 100px;

  @include media('>phone') {
    width: 20%;
    height: auto;
  }

  flex: 0 0 auto;

  img {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.content {
  @include media('>phone') {
    display: flex;

    > div {
      width: 50%;
      flex: 0 0 auto;
      min-width: 0;
    }
  }
}

.contentLeft {
  padding-right: 10px;
}

.contentRight {
  padding-left: 10px;
}

.eventDetails {
  padding: 20px;
  flex: 1 1 auto;
  min-width: 0;
}

.eventTime,
.eventLocation,
.eventLink,
.eventNumbers {
  margin-bottom: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 0;

  img {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    top: -2px;
    margin-right: 20px;
  }
}

.saveBtn {
  background: #000;
  color: #fff;
  border: 1px solid #000;
  border-radius: 500px;
  padding: 7px 20px 5px;
  text-transform: uppercase;
  font-size: 10px;
  cursor: pointer;

  &.active {
    background: #fff;
    color: #000;
  }
}

// editor

.editor {
  padding: 20px 40px;
  width: 100%;

  .body {
    margin-top: 30px;
  }

  .actions {
    padding-top: 20px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    button,
    input {
      align-self: center;
      white-space: nowrap;
      margin: 0;
    }
  }
}

.deleteEventModal {
  min-height: 0%;

  .deleteActions {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 32px;

    button {
      margin: 0;
      &:not(:last-child) {
        margin-left: 16px;
      }
    }
  }
}

// details

.eventNotFound {
  padding: 32px;
}

.event {
  width: 100%;
}

.eventDetailsImage {
  display: block;
  width: 100%;
  max-height: 30vh;
  object-fit: cover;
}

.detailedViewMeta {
  background: #f7f7f7;
}

.detailedEvent {
  padding: 20px 40px;

  .eventTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      color: #5cb985;
    }

    .eventActions {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;

      a,
      button {
        align-self: center;
        text-decoration: none;
        white-space: nowrap;
        margin: 0;
        margin-bottom: 8px;

        &:not(:last-child) {
          margin-right: 8px;
        }
      }
    }
  }
}

.detailedViewBody {
  padding: 40px 0;
}

.disabled {
  pointer-events: none;
  background-color: #eee !important;
  color: #ccc !important;
}
