@import '../imports.scss';

.Section {
  // desktop
  height: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: stretch;

  .section-sidebar {
    display: flex;
    height: 100vh;
  }
  .section-content {
    display: flex;
    flex: 1 1 auto;
    overflow-y: auto;
  }

  @include media('≤phone') {
    flex-direction: column;
    height: 100%;

    .MainNav {
      order: 3;
    }

    .section-content {
      display: flex;
      order: 1;
    }

    .section-sidebar {
      order: 1;
      display: none;
    }

    &.prime {
      .section-content {
        display: none;
      }

      .section-sidebar {
        display: flex;
        flex: 1 1 auto;
        > * {
          width: 100%;
        }
      }
    }
  }
}
