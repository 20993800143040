@import '../imports.scss';

.MapScreen {
  .marker-cluster {
    background-color: rgba($soft-green, 0.3);
    color: white;
    > div {
      background-color: $soft-green;
      font-family: $font-family;
      font-weight: normal;
    }
  }
  .markercluster-map {
    flex: 1;
  }
}