@import "../imports.scss";

.Replies {

  .header {
    width: $message-column-width;
    margin: 1em auto;
    color: $charcoal-grey
  }

  hr {
    width: $message-column-width + 50;
    border: 0.5px solid $duck-egg-blue;
    margin: 1.5em auto;
  }

  .conversation-header {
    padding: 32px;

    .Message {
      margin: 0 auto;
    }
  }
  .replies {
    margin: 24px auto;
  }
  .Chatbox, .Chatbox-join {
    box-shadow: none;
    margin: 0 auto;
    width: 100%;
    max-width: $message-column-width;
    .Button { border-radius: 32px; }
  }

}