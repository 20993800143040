@import "../imports.scss";

.Magazine {
  > .desktop-view {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  > .phone-view {
    display: none;
  }

  @include media("≤phone") {
    .phone-view {
      display: block;
    }

    .desktop-view:not(.alsoPhone) {
      display: none;
    }
  }

  .notice-section {
    padding-left: 24px;
  }
}

.GroupsMagazine {

  h1 {
    padding: 12px;
    font-size: 32px;
    font-weight: 500;
    color: $soft-green;
  }

  @include media("≤phone") {
    .SearchField {
      padding: 12px;
    }
  }

}
