@import '../imports.scss';


.AttachmentsModal {
  .tabs {
    flex-direction: row;
    display: flex;
    user-select: none;

    .tab {
      transition: all 0.3s;

      text-align: center;
      padding: 8px 8px;
      cursor: pointer;
      color: $ocean-blue;
      font-size: 16px;
      font-weight: 300;
      border-bottom: 1px solid rgba($ocean-blue, 0.3);
      margin-right: 8px;

      &.active {
        border-color: $ocean-blue;
      }
    }
  }

  .MentionsEditor {
    .tagged {
      flex-wrap: wrap;
      margin: 0 auto;
    }

    .tagged-indicator {
      background-color: $light-teal;
    }

    .tag-button {
      background-color: white;
      color: $cornflower-blue;
    }
  }

  .editor {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .actions {
    text-align: right;
    .Button {
      margin-right: 0;
      font-size: 20px;
      padding-left: 40px !important;
      padding-right: 40px !important;
      box-shadow: 0 0 16px 0 rgba(86, 124, 147, 0.5);
    }
  }
}

.ImageAttachmentsEditor {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  .Dropzone {
    width: 100%;
    height: 100%;
    border: 1px solid $cornflower-blue;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    color: $cornflower-blue;
    cursor: pointer;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.ReactModalPortal .Mask {
  background-color: rgba(255, 255, 255, 0.8);
}