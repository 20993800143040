@import '../imports.scss';

.Card.Post {
  color: $charcoal-grey;
  margin: 0px;
  box-shadow: 0 0 16px 0 $dark-sky-blue;
  cursor: pointer;
  font-size: 20px;
  word-wrap: break-word;

  .inset {
    padding: 12px;
  }

  .header {
    min-height: 64px;
    color: white;
    padding: 12px;
  }

  .Column {
    justify-content: space-around;
  }

  .Avatar {
    margin: 0 12px 0 0;
    height: 40px;
    width: 40px;
    flex-shrink: 0;
  }

  .star {
    color: #fdba12;
  }

  .ModerationTools {
    margin-left: -12px;
    margin-right: -12px;
    .toggle {
      right: 12px;
      width: 20px;
    }

    .open-tools {
      transition: all 0.3s;
    }
    &.open {
      .open-tools {
        margin-top: 12px;
      }

    }
  }
}