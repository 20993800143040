@import '../imports.scss';

.Threads {
  .Chatbox, .Chatbox-join {
    box-shadow: none;
    margin: 0 auto 24px auto;
    max-width: $message-column-width;
    .Button { border-radius: 32px; }
  }

  .Message {
    margin-bottom: 16px;
    border-radius: 4px;
    box-shadow: 0 0 16px 0 rgba($dark-sky-blue, 0.25);

    padding-bottom: 8px;

    .message-footer {
      margin-top: 15px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .reply-count {
        display: flex;
        flex-direction: row;
        align-items: baseline;
      }


      .comment-icon {
        margin-right: 8px;
        align-self: center;
        font-size: 20px;
      }

      .replies {
        font-size: 16px;
        font-weight: 500;
      }

      .last-updated {
        margin-left: 8px;
        font-size: 12px;
      }
    }
  }
}