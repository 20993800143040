@import '../imports.scss';

.MemberList {
  .member-list-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 24px 12px;
    //max-width: 800px;
    &:empty {
      display: none;
    }

  }

  display: flex;
  // display: none; // This change is temporary. 
  flex-direction: row;
  justify-content: center;

  .MemberListSection {
    flex: 0 1 30%;
    margin: 12px;
  }

  .mod {
    background-color: #99f9c2;
  }

  .muted {
    background-color: #ffdd89;
  }

  .banned {
    background-color: $coral;
  }

  .role-badge {
    display: none;
  }

  .Card {
    margin: 0;
    min-width: 250px;
  }

  h3 {
    display: block;
    font-size: 12px;
    color: $ocean-blue;
    //padding-top: 20px;
    margin-bottom: 9px;
    margin-top: 0;
    font-weight: normal;
  }

  @include media("≤phone") {
    &:not(.isAdmin) {
      .member-list-wrapper.hideOnMobile { display: none }
      .role-mod { display: none }
    }
  }
}