@import '../imports.scss';

.Sidebar {
  .NavMenu {
    width: 240px;
  }
}

.SidebarMenu {
  .NavMenu {
    color: rgba(222, 242, 255, 0.8);
    background-color: rgba(0, 108, 171, 0.85);
  }
  box-shadow: 0 0 16px 0 rgba(195, 227, 246, 0.5);

  .NavItem,
  h3 {
    padding: 12px 16px 12px 32px;
    color: rgba(222, 242, 255, 0.8);
    transition: background-color 0.3s;
  }

  .nav-label {
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .active {
    background-color: $cornflower-blue !important;
    color: white !important;
    font-weight: bold;
    border-right: $light-teal 8px solid;
    padding-right: 16px - 8px;
  }

  h3 {
    font-weight: 300;
    margin-bottom: 12px;
  }

  .Badge {
    &:empty {
      display: none;
    }
    margin: 0;
    color: $charcoal-grey;
    font-weight: bold;
  }

  .allGroups {
    margin: 24px 0;
    font-size: 20px;
    &.active {
      font-weight: 400 !important;
    }
  }
}
