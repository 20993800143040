@import '../imports.scss';

.Tabs {
  display: flex;
  flex: 0 0 24px;

  div {
    border-top: 2px solid $duck-egg-blue;
    color: $duck-egg-blue;
    margin: 5px;
    padding: 8px;
    font-size: 12px;

    &.active {
      border-top: 2px solid $ocean-blue;
      color: $ocean-blue;
    }
  }
}
