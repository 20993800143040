@import '../imports.scss';

.ProfilePreview {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px 0;
  //margin-bottom: 24px;

  &.simple {
    padding: 0;
  }

  .profile-chunk {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1 1 auto;
    overflow: hidden;
  }

  .profile-roles {
    margin-top: 5px;
    display: flex;
    flex-wrap: wrap;
  }

  .role-badge {
    align-self: flex-start;
    margin-right: 12px;
    padding: 1px 2px 2px 2px;
  }
  .card-avatar {
    align-self: flex-start;
  }

  .Avatar {
    margin: 0;
    margin-right: 16px;
  }

  .actions {
    margin-bottom: 0;
  }
  .profile-details {
    flex: 1 1 auto;

    margin-bottom: 4px;
    .profile-name {
      font-size: 16px;
      font-weight: normal;
    }

    .profile-location {
      font-size: 12px;
      margin-top: 8px;
    }
  }
}
