.ReactModal__Content {
  box-shadow: 0 0 64px rgba(15, 12, 31, 0.25);
  padding: 2rem;
  background-color: white;
  min-width: 375px;
  width: 50%;
  max-width: 60rem;
  overflow: scroll;

  .modal-close {
    float: right;
    cursor: pointer;
  }
}
