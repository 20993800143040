@import '../imports.scss';

.MapHeader {
  .NavMenu {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .Select {
    max-width: 500px;
    flex: 1;
    border-radius: 4px;
    background-color: $white;
    border: solid 1px $ocean-blue;

    .Select-control {
      font-size: 16px;
      background-color: transparent;
      border-radius: 4px;
      padding: 14px 16px 14px 16px !important;
      line-height: 16px;
    }
  }
  padding: 8px 24px;
}

.AngleRenderer {
  transition: all 0.3s;

  &.open {
    transform: scaleY(-1);
  }
}