@import '../imports.scss';

.Button.SkipToNowButton {
  border-radius: 100%;
  background-color: $dark-sky-blue;
  color: white;
  background-size: cover;
  text-align: center;
  overflow: hidden;


  .skip-down-indicator {
    transition: transform 0.3s ease-in-out;
  }

  //&.atBottom {
  //  .skip-down-indicator {
  //    transform: translateY(40px);
  //  }
  //}
}