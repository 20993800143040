@import '../../imports.scss';

.SortOrderModal {
  &.main {
    min-height: 70%;
  }

  .list {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .new-key {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .Input {
      margin: 0 12px;
      width: -webkit-fill-available;
    }

    .Button {
      margin-top: 12px;
    }

    p.error {
      margin: 12px;
      color: $error;
    }
  }
}

.SortKey {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;

  .actions {
    display: flex;
    align-items: center;

    .delete {
      font-size: small;
      cursor: pointer;
      color: $primary;
      margin-right: 24px;
    }
  }
}
